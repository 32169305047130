$w-btn-next: 2.8rem;
$h-btn-next: 2.8rem;

$w-index-container: 7.7rem;
$h-index-container: 2.8rem;

$bg-color-index-container: #082c6b;
$border-index-container: 3px solid #ffba1a;

.pagination {
  @apply flex items-center justify-center text-white text-2xl;
  .btn-previous {
    @apply p-0 bg-btn-previous bg-center bg-cover bg-no-repeat bg-transparent cursor-pointer mr-6;
    width: $w-btn-next;
    height: $h-btn-next;
    &:disabled {
      @apply filter grayscale text-gray-300;
    }
  }
  .btn-next {
    @apply p-0 bg-btn-next bg-center bg-cover bg-no-repeat bg-transparent cursor-pointer ml-6;
    width: $w-btn-next;
    height: $h-btn-next;
    &:disabled {
      @apply filter grayscale text-gray-300;
    }
  }
  .index-container {
    @apply flex justify-center items-center rounded-xl mr-2 text-center;
    width: $w-index-container;
    height: $h-index-container;
    background-color: $bg-color-index-container;
    border: $border-index-container;
  }

  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  input[type='number'] {
    -moz-appearance: textfield;
  }
}
