$w-filter: 35rem;
$h-filter: 7.3rem;

.filter-header {
  @apply bg-filter-header bg-center bg-no-repeat bg-cover px-12 py-9 flex justify-between items-center;
  width: $w-filter;
  height: $h-filter;

  .left-content {
    @apply font-black uppercase;
    font-size: 2rem;
    background: -webkit-linear-gradient(45deg, #ffba1a 0.96%, #ffe9af 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }

  .right-content {
    @apply font-medium;
    font-size: 2rem;
    color: #00cffe;
  }
}
