$w-max-left: 34.9rem;

.marketplace {
  @apply w-full justify-between grid-cols-3 2xl:grid-cols-5 gap-36;

  .left {
    @apply items-center sticky;
    max-width: $w-max-left;
  }

  .right {
    @apply col-span-2 2xl:col-span-4 justify-start;
  }
}
