$w-wallet-detail: 52.6rem;
$h-wallet-detail: 23.3rem;

$bg-color-wallet-detail: #082c6b;
$border-coin-block: 2px solid #ffba1a;

$color-balance-currency-1: #00d8ff;

$fs-address: 2.4rem;
$fs-balance-title: 2.4rem;
$fs-balance-currency-1: 4rem;
$fs-balance-currency-2: 1.6rem;

.wallet-detail-container {
  &:before {
    @apply bg-wallet-detail bg-contain bg-no-repeat;
    content: ' ';
    display: block;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    opacity: 0.05;
    background-position: bottom right;
    z-index: -1;
  }

  @apply rounded-xl pt-12 pb-10 px-16 flex flex-col justify-between;
  width: $w-wallet-detail;
  height: $h-wallet-detail;
  border: $border-coin-block;
  background-color: $bg-color-wallet-detail;
  z-index: 1;

  .address-container {
    @apply flex items-center;
    .address {
      @apply text-white font-bold;
      font-size: $fs-address;
    }

    .icon-copy {
      @apply ml-4 w-10 h-10 cursor-pointer;
      display: block !important;
    }
  }

  .balance-container {
    @apply flex flex-col;

    .title {
      @apply text-white font-bold;
      font-size: $fs-address;
    }

    .currency-1 {
      @apply font-bold;
      color: $color-balance-currency-1;
      font-size: $fs-balance-currency-1;
    }

    .currency-2 {
      @apply text-white font-black;
      font-size: $fs-balance-currency-2;
    }
  }
}
