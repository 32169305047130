@use 'sass:meta';

$w-modal-large: 106.6rem;
$h-modal-large: 79.4rem;

$w-modal-small: 78.9rem;
$h-modal-small: 58.9rem;

$w-modal-banner: 79.4rem;
$h-modal-banner: 79.4rem;
$max-w-img: 52.5rem;
$max-w-img-sm: 20rem;

$color-title: #242e85;
$fs-title: 4.8rem;

$color-title-sm: #705512;
$fs-title-sm: 4rem;

$color-subtitle: #082c6b;
$color-error: #ff1a1a;

@include meta.load-css('./layouts/buy-items/item-details.scss');

.modal {
  &-container {
    @apply h-full flex flex-col;
    width: $w-modal-large;
  }

  &-overlay {
    @apply z-50 fixed top-0 right-0 left-0 bottom-0 flex items-center justify-center;
    background-color: rgba(0, 0, 0, 0.7);
  }

  /* modal attributes */
  @apply bg-img-modal bg-center bg-no-repeat bg-cover flex items-center justify-center px-24 py-30 relative m-auto;
  // top: 50%;
  // left: 50%;
  // right: auto;
  // bottom: auto;
  // margin-right: -50%;
  // transform: translate(-50%, -50%);
  border: none;
  width: $w-modal-large;
  height: $h-modal-large;

  .icon-close {
    @apply absolute right-24 top-28 cursor-pointer;
  }

  &.small {
    width: $w-modal-small;
    height: $h-modal-small;
    @apply p-28;
  }

  /* default layouts */
  &-layout {
    @apply flex flex-col items-center justify-center max-h-full;
  }

  /* components */
  &-banner {
    @apply mb-8;
  }

  &-title {
    @apply font-black text-center;
    color: $color-title;
    font-size: $fs-title;
  }

  &.small &-title {
    @apply font-bold uppercase mb-8;
    color: $color-title-sm;
    font-size: $fs-title-sm;
  }

  &.small.error &-title {
    color: $color-error;
  }

  &-info-layout {
    @apply flex;

    & .modal-banner {
      max-width: $max-w-img;
      margin: 0;
    }
  }

  &.small &-info-layout {
    @apply flex gap-8;

    & .modal-img-item {
      max-width: $max-w-img-sm;
    }
  }

  &-info {
    @apply flex flex-col justify-center;
  }

  &-content {
    @apply flex flex-col items-start justify-center mb-8 max-h-full;

    h2 {
      @apply text-info-header font-bold uppercase mb-6;
      color: $color-title-sm;
    }

    p {
      @apply text-content;
      color: $color-subtitle;
    }

    &.owner {
      @apply font-black text-info-header flex-row justify-start;

      h2 {
        @apply font-black uppercase mb-6;
        color: $color-title-sm;
      }

      span {
        @apply ml-2;
        color: $color-subtitle;
      }
    }
  }

  &.small &-content {
    .modal-subtitle {
      @apply text-content text-center normal-case mb-8;
      color: $color-subtitle;
    }
  }

  &.small.error &-content {
    .modal-subtitle {
      color: $color-error;
    }
  }

  &.small &-subtitle {
    @apply max-w-lg;

    h2 {
      @apply mb-8;
    }

    p {
      @apply font-bold text-center;
    }
  }

  &.small &-subtitle-left &-content {
    h2 {
      text-align: left;
    }
  }

  &-table {
    @apply w-full text-content;
    color: $color-title-sm;

    li {
      @apply mb-3;
    }
  }

  &-btn-actions {
    @apply flex items-center justify-center gap-8 z-10 w-full;
  }

  &-buy-box {
    > h1 {
      @apply normal-case;
    }
  }

  &-processing {
    @apply flex flex-col justify-center items-center gap-8;

    & img {
      @apply cursor-default;
    }
  }

  &-details {
    .id-label {
      @apply text-content text-white font-black rounded-md px-6 py-3 my-12 inline-block;
      background-color: #04142d;
    }

    .gear-container {
      @apply flex items-center justify-center;
      background-color: $color-title-sm;
      width: 43rem;
      height: 43rem;
      border: 4px solid #ffba1a;

      img {
        height: 25rem;
        width: 25rem;
      }
    }

    .modal-content {
      color: $color-title-sm;
    }

    .modal-subtitle {
      @apply text-content font-black;
    }

    .price-block {
      @apply flex flex-col text-info-header;

      h6 {
        @apply text-title font-bold;
        color: $color-title-sm;
      }

      span {
        @apply font-black;
        color: $color-subtitle;
      }
    }
  }

  &-terms-of-use {
    @apply flex flex-col justify-center items-center gap-8;

    .title {
      @apply font-black;
      color: $color-title-sm;
      font-size: $fs-title;
    }

    .content {
      @apply text-2xl mt-6 text-black pt-0 overflow-y-scroll overflow-x-hidden;

      max-height: 90%;
    }
  }

  &-set-price {
    .input-number input {
      @apply text-left;
    }
  }
}
