$w-skill-container: 8.8rem;
$h-skill-container: 8.8rem;

.skill-container {
  width: $w-skill-container;
  height: $h-skill-container;

  img {
    @apply w-full h-full;
  }
}
