$blockInfoWidth: 386px;
$fontColor: #705512;

.info-block {
  @apply bg-transparent h-auto;
  color: $fontColor;
  width: $blockInfoWidth;

  > .info-title {
    @apply text-info-header font-black uppercase mb-4;
  }

  > .info-content {
    @apply text-content font-normal;
  }
}
