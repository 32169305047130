$color-btn-back: #ffba1a;

.btn-back {
  @apply font-black text-info-header flex items-center mb-3;
  color: $color-btn-back;

  .icon-left-arrow {
    @apply mr-3;
  }
}
