$bg-input: #082c6b;

$border-color: #ffba1a;
$border-input: 1px solid $border-color;
$border-radius-input: 0.4rem;

$color-input-number-max: #5da8ec;
$color-label: #705512;

$h-input: 5.6rem;
$w-input: 27rem;

$p-input: 1.6rem 2.4rem;

$w-icon-btn: 3.8rem;
$h-icon-btn: 2.8rem;

$w-input-email: 40.8rem;

$w-icon-email-check: 2rem;
$h-icon-email-check: 2rem;

$color-email-verified: #38ae25;

$fs-email-check: 1.6rem;

.input {
  @apply flex flex-1 items-center justify-center text-white text-input;
  background-color: $bg-input;
  padding: $p-input;
  height: $h-input;
  width: $w-input;
  border: $border-input;
  border-radius: $border-radius-input;

  input {
    @apply w-full;
    background-color: $bg-input;
  }

  input:focus {
    @apply outline-none;
  }

  &-label {
    @apply font-black text-info-header mb-6 block;
    color: $color-label;
  }

  &-prefix {
    @apply -ml-4 mr-6;
  }

  &-number {
    .icon-minus {
      @apply bg-img-icon-minus bg-center bg-no-repeat bg-cover cursor-pointer;
      height: $h-icon-btn;
      width: $w-icon-btn;
    }

    .icon-plus {
      @apply bg-img-icon-plus bg-center bg-no-repeat bg-cover cursor-pointer;
      height: $h-icon-btn;
      width: $w-icon-btn;
    }

    input {
      @apply text-center cursor-default;
    }

    input[type='number']::-webkit-inner-spin-button,
    input[type='number']::-webkit-outer-spin-button {
      @apply m-0 appearance-none;
    }

    input[type='number'] {
      appearance: textfield;
    }

    button:focus {
      @apply outline-none;
    }

    &-max {
      @apply uppercase text-input font-bold ml-6 cursor-pointer;
      color: $color-input-number-max;
    }
  }

  &-email {
    width: $w-input-email;

    .email-check {
      @apply flex justify-center items-center;

      &.verified {
        .icon {
          @apply bg-img-icon-green-tick bg-center bg-no-repeat bg-cover cursor-pointer mr-5;

          height: $h-icon-email-check;
          width: $w-icon-email-check;
        }

        p {
          @apply font-extrabold;
          color: $color-email-verified;
          font-size: $fs-email-check;
        }
      }
    }
  }
}
