$min-h-content: 40rem;

.modal-item-content {
  @apply overflow-y-auto pr-4 pb-4;
  height: $min-h-content;
  min-height: $min-h-content;
}

.modal-item-actions {
  @apply flex mt-12;
}

.modal-item-btn-buy {
  @apply text-info-header uppercase;
}
