@use 'sass:meta';

@include meta.load-css('./components/back/btn-back');

$border-primary-gradient: linear-gradient(91.43deg, #ff9e1a 0.96%, #ffc32f 100%);
$bg-primary-gradient: linear-gradient(144.47deg, #ffe241 -3.7%, #ffda3c 12.74%, #ffc32f 38.78%, #ff9e1a 70.95%, #ff7804 98.9%);

$border-secondary-gradient: #4b5c68;
$bg-secondary-gradient: linear-gradient(90deg, rgba(45, 66, 79, 1) 0%, rgba(59, 86, 105, 1) 50%, rgba(45, 66, 79, 1) 100%);

$border-disabled-gradient: #a4a4a4;
$bg-disabled-gradient: linear-gradient(90deg, rgba(144, 144, 144, 1) 0%, rgba(162, 162, 162, 1) 50%, rgba(144, 144, 144, 1) 100%);

$bg-hover-btn-outlined: #082c6b;

$gradient-darken: linear-gradient(0deg, rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.1));

$color-shadow-primary: #d18312;
$color-shadow-secondary: #223038;
$color-shadow-disabled: #7b7b7b;

$color-brown: #705512;
$color-white: white;
$color-darkgray: #404040;
$color-btn-modal: linear-gradient(180deg, #ffe241, #ff7804);
$color-btn-switch-background: #642537;
$color-btn-switch-background-active: #0a7b7e;
$color-btn-switch-pointer: #e7515c;
$color-btn-switch-pointer-active: #28e0a7;

$transition-btn-switch: 0.2s;

$border-radius: 10px;
$border-btn-outlined: 1px solid #ffba1a;
$border-btn-switch: 2px solid #aa2d42;
$border-btn-switch-active: 2px solid #119699;
$borderRadius-btn-switch: 11.8125px;
$borderRadius-btn-switch-pointer: 9.5625px;

$ff-exo: 'Exo';
$p-btn-base: 1.5rem 0px 1.5rem 0px;

$w-btn-default: 100%;
$min-w-btn-default: 17.5rem;

$w-btn-login: 14.3rem;
$h-btn-login: 7.41rem;

$w-btn-modal: 26.5rem;
$h-btn-modal: 34.5rem;

$w-btn-outlined: 47.2rem;
$h-btn-outlined: 6.3rem;

$w-btn-checkbox: 2.8rem;
$h-btn-checkbox: 2.8rem;

$w-btn-switch: 6.22rem;
$h-btn-switch: 2.362rem;

$w-btn-switch-pointer: 35.71px;
$h-btn-switch-pointer: 18px;

$p-btn-modal: 1.5rem 1.5rem 1.5rem 1.5rem;

$left-btn-switch-pointer: 1.5px;
$bottom-btn-switch-pointer: 1px;

.btn {
  @apply px-8 py-2 rounded font-semibold bg-gray-200 text-black;
  font-family: $ff-exo;

  &-flex {
    @apply flex items-center justify-center;
  }

  &-success {
    @apply bg-green-700;
  }

  &:disabled {
    background: $bg-disabled-gradient;
    color: $color-darkgray;

    &:hover {
      background: $bg-disabled-gradient;

      &:before {
        background: $border-disabled-gradient;
      }
    }

    &:before {
      background: $border-disabled-gradient;
      box-shadow: 0px 0.5rem $color-shadow-disabled;
    }
  }

  &-base {
    @apply px-0 py-2 text-3xl font-black relative;
    border-radius: $border-radius;
    padding: $p-btn-base;
    width: $w-btn-default;
    min-width: $min-w-btn-default;
  }

  &-compact {
    @apply px-8;
    width: fit-content;
  }

  &:not(.btn-header):before {
    @apply absolute top-0 bottom-0 right-0 left-0 -m-2;
    content: '';
    z-index: -1;
    border-radius: inherit;
  }

  &-primary {
    background: $bg-primary-gradient;
    color: $color-brown;

    &:hover {
      background: $gradient-darken, $bg-primary-gradient;

      &:before {
        background: $gradient-darken, $border-primary-gradient;
      }
    }

    &:before {
      background: $border-primary-gradient;
      box-shadow: 0px 0.5rem $color-shadow-primary;
    }
  }

  &-secondary {
    background: $bg-secondary-gradient;
    color: $color-white;

    &:hover {
      background: $gradient-darken, $bg-secondary-gradient;

      &:before {
        background: $gradient-darken, $border-secondary-gradient;
      }
    }
    &:before {
      background: $border-secondary-gradient;
      box-shadow: 0px 0.5rem $color-shadow-secondary;
    }
  }

  &-login {
    @apply bg-btn-login text-4xl font-semibold bg-center bg-cover bg-no-repeat bg-transparent uppercase rounded-none p-0;
    color: $color-white;
    width: $w-btn-login;
    height: $h-btn-login;

    &:hover {
      @apply bg-btn-login bg-center bg-cover bg-no-repeat bg-transparent;
      &:before {
        @apply shadow-none;
        background: none;
      }
    }

    &:before {
      @apply shadow-none;
      background: none;
      content: '';
    }
  }

  &-modal {
    @apply bg-btn-modal bg-center bg-cover bg-no-repeat bg-transparent uppercase rounded-none p-0 divide-y-2 divide-yellow-400 cursor-default;

    width: $w-btn-modal;
    height: $h-btn-modal;
    padding: $p-btn-modal;

    &-header {
      @apply w-full h-4/5 flex justify-center	items-center p-4;

      img {
        @apply w-full h-auto cursor-default;
      }
    }
    &-footer {
      @apply w-full h-1/5 flex justify-center	items-center bg-cover bg-repeat;

      h6 {
        @apply text-info-header;

        background-color: $color-white;
        background-image: $color-btn-modal;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        -moz-background-clip: text;
        -moz-text-fill-color: transparent;
      }
    }
    &:hover {
      @apply bg-btn-modal bg-center bg-cover bg-no-repeat bg-transparent;

      &:before {
        @apply shadow-none;
        background: none;
      }
    }
    &:before {
      @apply shadow-none;

      background: none;
      content: '';
    }
  }

  &-outlined {
    @apply font-light text-4xl p-0 flex justify-center items-center;
    background: none;
    width: $w-btn-outlined;
    height: $h-btn-outlined;
    border: $border-btn-outlined;

    &:hover {
      @apply p-0;
      background: $bg-hover-btn-outlined;
    }

    &.btn-compact {
      @apply py-6 px-4;
      width: auto;
      height: auto;

      &:hover {
        @apply py-6 px-4;
      }
    }

    .icon {
      @apply w-12 h-12 mr-4 bg-center bg-no-repeat bg-contain;

      &-metamask {
        @apply bg-img-icon-metamask;
      }

      &-qrcode {
        @apply bg-img-icon-qrcode;
      }

      &-mail {
        @apply bg-img-icon-mail;
      }
    }
  }

  &-checkbox {
    @apply p-0 bg-btn-checkbox bg-center bg-cover bg-no-repeat bg-transparent;
    width: $w-btn-checkbox;
    height: $w-btn-checkbox;

    &.checked {
      @apply bg-btn-checkbox-checked;
    }
  }

  &-loader {
    @apply loader ease-linear rounded-full border-4 border-t-4 h-8 w-8 ml-4;
    border-top-color: $border-secondary-gradient;
  }
  &-switch {
    @apply relative inline-block;
    width: $w-btn-switch;
    height: $h-btn-switch;

    input {
      @apply opacity-0 w-0 h-0;
    }

    .slider {
      @apply absolute cursor-pointer top-0 left-0 right-0 bottom-0;
      background-color: $color-btn-switch-background;
      -webkit-transition: $transition-btn-switch;
      transition: $transition-btn-switch;
      border-radius: $borderRadius-btn-switch;
      border: $border-btn-switch;
    }

    .slider::before {
      @apply absolute cursor-pointer;
      content: '';
      height: $h-btn-switch-pointer;
      width: $w-btn-switch-pointer;
      left: $left-btn-switch-pointer;
      bottom: $bottom-btn-switch-pointer;
      background-color: $color-btn-switch-pointer;
      border-radius: $borderRadius-btn-switch-pointer;
      -webkit-transition: $transition-btn-switch;
      transition: $transition-btn-switch;
    }

    input:checked + .slider {
      background-color: $color-btn-switch-background-active;
      border: $border-btn-switch-active;
    }

    input:checked + .slider::before {
      background-color: $color-btn-switch-pointer-active;
    }

    input:checked + .slider:before {
      -webkit-transform: translateX(20px);
      -ms-transform: translateX(20px);
      transform: translateX(20px);
    }
  }
}

@include meta.load-css('./components/header/btn-header');
