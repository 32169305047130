$cardInfoWidth: 34.8rem;
$cardInfoHeight: 38.7rem;

$cardImgSize: 27.2rem;

.card-info {
  @apply bg-img-card-info bg-center bg-auto bg-no-repeat flex flex-col;
  width: $cardInfoWidth;
  height: $cardInfoHeight;

  .card-img {
    @apply overflow-hidden mx-auto mb-6 mt-9 relative;
    width: $cardImgSize;
    height: $cardImgSize;

    img {
      @apply absolute m-auto -inset-0;
    }
  }

  .card-caption {
    @apply flex flex-col justify-center items-center text-info-header text-white;

    &-header {
      @apply font-bold mb-1;
    }

    &-subheader {
      @apply flex justify-center items-center;

      .icon-copy {
        @apply ml-4;
      }
    }
  }
}
