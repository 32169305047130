$w-score: 33.1rem;
$h-score: 9.5rem;
$bg-color-score: #082c6b;
$border-score: 2px solid #ffba1a;
$fs-score-content: 2rem;
$fs-score-number: 2.4rem;

$color-score-number: #00c7f6;

.score-container {
  @apply box-border rounded-xl flex flex-col items-center justify-center;

  width: $w-score;
  height: $h-score;
  background-color: $bg-color-score;
  border: $border-score;

  .text {
    @apply text-white font-bold uppercase;

    font-size: $fs-score-content;
  }

  .number {
    @apply font-bold;

    font-size: $fs-score-number;
    color: $color-score-number;
  }
}
