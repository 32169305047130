$w-icon: 22.8rem;

.empty-transaction-container {
  @apply flex flex-col justify-center items-center;

  img {
    @apply h-auto;
    width: $w-icon;
  }

  p {
    @apply mt-5 text-2xl text-white text-center;
  }
}
