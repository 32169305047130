$w-filter-range: 35rem;
$h-filter-range: 2.8rem;

$wh-filter-range-expand-icon: 2.8rem;

$h-filter-range-content: 3.7rem;

$w-filter-range-thumb: 0.68rem;
$h-filter-range-thumb: 1.4rem;

$bg-color-rc-slider-track: #ffba1a;
$bg-color-rc-slider-rail: #00d8ff;

$bg-color-filter-range-content: #082c6b;
$border-filter-range-content: 3px solid #ffba1a;
$color-filter-range-title: #ffba1a;

$h-rc-slider-rail: 2px;
$mt-rc-slider-rail: 1px;

$h-rc-slider-step: 7px;

.range-container {
  .title-container {
    @apply flex items-center justify-between cursor-pointer;
    width: $w-filter-range;
    height: $h-filter-range;

    span {
      @apply font-black text-3xl uppercase tracking-wide;
      color: $color-filter-range-title;
    }

    .icon {
      @apply bg-center bg-no-repeat bg-cover;
      width: $wh-filter-range-expand-icon;
      height: $wh-filter-range-expand-icon;
      &-minus {
        @apply bg-img-icon-minus bg-center bg-no-repeat bg-cover;
      }
      &-plus {
        @apply bg-img-icon-plus bg-center bg-no-repeat bg-cover;
      }
    }
  }

  .content-container {
    @apply hidden flex-col max-h-0 mt-2 rounded-xl px-4 justify-center;

    background-color: $bg-color-filter-range-content;
    width: $w-filter-range;
    height: $h-filter-range-content;
    border: $border-filter-range-content;

    &.expand {
      @apply flex max-h-full;
    }

    .rc-slider {
      @apply cursor-pointer;
      .rc-slider-step {
        height: $h-rc-slider-step;
        .rc-slider-dot {
          @apply border-none rounded-none -mt-2 cursor-pointer;
          background: $color-filter-range-title;
          width: $w-filter-range-thumb;
          height: $h-filter-range-thumb;
          border-radius: 1px;
        }
      }

      .rc-slider-handle {
        @apply hidden cursor-pointer;
      }

      .rc-slider-track {
        background-color: $bg-color-rc-slider-track;
      }

      .rc-slider-rail {
        height: $h-rc-slider-rail;
        margin-top: $mt-rc-slider-rail;
        background-color: $bg-color-rc-slider-rail;
      }

      .rc-slider-mark {
        .rc-slider-mark-text {
          @apply text-2xl font-bold mt-4;
          color: $color-filter-range-title;
        }
      }
    }
  }
}
