$w-max-left: 34.8rem;
$gap-col: 9.4rem;
$w-container: 132rem;

.inventory {
  &-container {
    @apply w-full grid grid-cols-3;
    width: $w-container;
    gap: $gap-col;
    .left {
      max-width: $w-max-left;
      .menu {
        @apply flex flex-col gap-12 mt-12 sticky;
        &-item {
          @apply w-full;
        }
      }
    }

    .right {
      @apply col-span-2 flex flex-col justify-start;

      .menu {
        @apply sticky;
      }

      .tab-panel {
        @apply mt-2;
        .boxes {
          @apply flex gap-10 flex-wrap;
        }
        .pagination {
          @apply justify-center;
        }
      }
    }
  }
}
