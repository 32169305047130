$w-img-logo: 23rem;
$h-img-logo: 11.3rem;

$bg-color-header: #11131b;

.header {
  @apply fixed z-40 w-full max-w-full max-h-32 px-6 flex justify-items-start items-center;
  background-color: $bg-color-header;

  img {
    @apply ml-2 self-start mt-8;
    width: $w-img-logo;
    height: $h-img-logo;
  }

  &-btn-container {
    @apply ml-4 flex gap-x-4;

    .active .btn-header {
      @apply bg-btn-header-active;
    }
  }

  &-btn-login {
    @apply ml-auto;
  }
}
