@import './info-block/info-block.scss';
@import './list/list.scss';
@import './progress-bar/progress-bar.scss';
@import './header-info/header-info.scss';
@import './card-info/card-info.scss';
@import './alert/alert.scss';
@import './empty/empty.scss';
@import './pagination/pagination.scss';
@import './skill/skill.scss';
@import './score/score.scss';
@import './coin-block/coin-block.scss';
@import './wallet-detail/wallet-detail.scss';
@import './table/table.scss';
@import './item-card/item-card.scss';
@import './countdown/countdown.scss';

/* CSS for general display items, such as coins, icons */
.bitcoin {
  @apply bg-img-bitcoin bg-center bg-no-repeat bg-cover h-10 w-9;
}

.icon-copy {
  @apply bg-img-icon-copy bg-center bg-no-repeat bg-cover h-8 w-8;
}

.icon-warning {
  @apply bg-img-icon-warning bg-center bg-no-repeat bg-cover h-10 w-10 block;
}

.icon-close {
  @apply bg-img-icon-close bg-center bg-no-repeat bg-cover h-16 w-16;
}

.icon-left-arrow {
  @apply bg-img-icon-left-arrow bg-center bg-no-repeat bg-cover h-12 w-12 inline-block;
}
