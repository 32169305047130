$bg-color-main: #242735;

.warning-mobile {
  @apply w-screen h-screen bg-img-container bg-no-repeat bg-bottom flex justify-center items-center text-white;

  background-color: $bg-color-main;
  background-size: 100% auto;

  h2 {
    @apply text-4xl;
  }
}
