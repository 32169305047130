$w-item-card: 25.7rem;
$h-item-card: 41.9rem;

$w-item-card-header: 25.7rem;
$h-item-card-header: 32.5rem;
$p-item-card-header-img: 0.9rem;

.item-card-container {
  @apply flex flex-col cursor-pointer;

  width: $w-item-card;
  height: $h-item-card;

  .card-header {
    @apply relative;
    width: $w-item-card-header;
    height: $h-item-card-header;

    &-red {
      @apply bg-item-card-red bg-center bg-no-repeat bg-cover;
    }
    &-blue {
      @apply bg-item-card-blue bg-center bg-no-repeat bg-cover;
    }
    &-brown {
      @apply bg-item-card-brown bg-center bg-no-repeat bg-cover;
    }
    &-green {
      @apply bg-item-card-green bg-center bg-no-repeat bg-cover;
    }
    &-grey {
      @apply bg-item-card-grey bg-center bg-no-repeat bg-cover;
    }
    &-purple {
      @apply bg-item-card-purple bg-center bg-no-repeat bg-cover;
    }

    img {
      padding: $p-item-card-header-img;
    }

    .card-header-details {
      @apply w-full absolute bottom-5 flex flex-col justify-center items-center;

      &-name {
        @apply text-white font-bold;
        font-size: 2.4rem;
      }
    }

    .shadow {
      @apply w-full h-40 absolute bottom-0 rounded-b-sm;

      background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.5) 48.44%, #000000 100%);
      opacity: 0.8;
      border-bottom-right-radius: 0.8rem;
      border-bottom-left-radius: 0.8rem;
    }
  }

  .card-footer {
    @apply w-full h-full flex flex-col items-center justify-around pt-4 flex-wrap;

    .price {
      @apply text-white font-bold;
      font-size: 2.8rem;
    }

    .converted-price {
      @apply font-bold;
      font-size: 2.4rem;
      color: #5da8ec;
    }
  }
}
