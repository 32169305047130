$bg-color: rgba(112, 85, 18, 0.3);
$bg-color-hover: rgba(112, 85, 18, 0.7);
$border: 1px solid #ffba1a;

.table-row {
  @apply lg:hover:bg-gray-100 flex flex-wrap lg:flex-nowrap mb-10 lg:mb-0 text-white w-full;
  background-color: $bg-color;
  border-bottom: $border;

  &:hover {
    background-color: $bg-color-hover;
  }
}
