$bg-color: #082c6b;
$color: #ffba1a;
$color-title: #ffc444;

$min-w: 49.6rem;
$min-h: 6.9rem;

.countdown {
  @apply flex border-2 rounded-md items-center justify-evenly;
  min-width: $min-w;
  min-height: $min-h;
  background-color: $bg-color;
  border-color: $color;

  > div {
    @apply flex flex-col;

    > span:first-child {
      @apply text-white text-title font-bold;
    }
    > span:last-child {
      @apply text-content font-normal;
      color: $color;
    }
  }

  &-title {
    @apply font-bold text-title;
    color: $color-title;
  }

  &-timer {
    @apply font-bold text-title text-white tracking-wider;
  }
}
