$w-filter-select: 30.5rem;
$h-filter-select: 5rem;

$w-sell-select: 16.6rem;
$h-sell-select: 6.1rem;

$color-filter-select-title: #ffba1a;
$border-left-filter-select-title: 3px solid #ffba1a;
$border-right-filter-select-title: 3px solid #ffe9af;
$bg-image-filter-select-title: linear-gradient(90deg, #ffba1a, #ffe9af), linear-gradient(90deg, #ffba1a, #ffe9af);
$bg-size-filter-select-title: 100% 3px;
$bg-position-filter-select-title: 0 0, 0 100%;

$bg-color-filter-select-content: #082c6b;
$bg-color-sell-select: #0a2b67;

.filter {
  &-switchBox {
    @apply flex justify-between px-6 text-white cursor-default;
  }

  &-select {
    @apply relative inline-block text-left;

    .title-container {
      width: $w-filter-select;
      height: $h-filter-select;

      button {
        @apply flex justify-between items-center w-full h-full rounded-2xl text-3xl bg-no-repeat bg-transparent px-6;

        color: $color-filter-select-title;
        border-left: $border-left-filter-select-title;
        border-right: $border-right-filter-select-title;
        background-image: $bg-image-filter-select-title;
        background-size: $bg-size-filter-select-title;
        background-position: $bg-position-filter-select-title;

        .title-left {
          @apply font-medium;
          font-size: 2rem;
        }

        .title-right {
          @apply flex flex-row items-center font-bold text-3xl text-white;

          svg {
            @apply -mr-2 ml-2 h-8 w-8;
          }
        }
      }
    }

    .content-container {
      @apply hidden origin-top-right absolute right-0 mt-2 p-8 w-auto rounded-2xl z-50;
      background-color: $bg-color-filter-select-content;

      &.expand {
        display: block;
      }

      a {
        @apply text-3xl text-white block px-4 my-2 cursor-pointer;
      }
    }
  }
}

.sell-select {
  @apply relative inline-block text-left;

  .title-container {
    @apply flex flex-row justify-between;

    .title-left {
      @apply font-bold my-auto text-2xl text-white mr-6;
    }

    .title-right {
      @apply flex flex-row justify-between items-center h-full rounded-2xl text-2xl bg-no-repeat px-6 font-bold text-white;

      width: $w-sell-select;
      height: $h-sell-select;
      border-left: $border-left-filter-select-title;
      border-right: $border-right-filter-select-title;
      background-color: $bg-color-sell-select;
      background-image: $bg-image-filter-select-title;
      background-size: $bg-size-filter-select-title;
      background-position: $bg-position-filter-select-title;

      svg {
        @apply -mr-2 h-8 w-8;
      }
    }
  }

  .content-container {
    @apply hidden origin-top-right absolute right-0 mt-2 p-8 w-auto rounded-2xl z-50;
    background-color: $bg-color-filter-select-content;

    &.expand {
      display: block;
    }

    a {
      @apply text-3xl text-white block px-4 py-2 cursor-pointer;
    }
  }
}
