$w-btn-header: 14.3rem;
$h-btn-header: 4.92rem;

.btn-header {
  @apply bg-btn-header text-2xl font-semibold bg-center bg-no-repeat bg-transparent uppercase rounded-none p-0 text-white;
  background-size: $w-btn-header;
  min-width: $w-btn-header;
  height: $h-btn-header;

  &.active,
  .active &-header {
    @apply bg-btn-header-active;
  }

  &:hover {
    @apply bg-btn-header-active bg-center bg-no-repeat bg-transparent;
    background-size: $w-btn-header;

    &:before {
      @apply shadow-none;
      background: none;
    }
  }

  &:before {
    @apply shadow-none;
    background: none;
    content: '';
  }

  &:disabled {
    @apply bg-btn-header bg-center bg-no-repeat bg-transparent filter grayscale text-gray-300;
    background-size: $w-btn-header;

    &:hover {
      @apply bg-btn-header bg-center bg-no-repeat bg-transparent;
      background-size: $w-btn-header;
    }
  }
}
