$bg-color: #ffba1a;

.table-head {
  @apply flex;
  background-color: $bg-color;

  .table-row {
    width: calc(100% - 1.6rem);
  }
}
