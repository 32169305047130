$w-price: 35rem;
$h-price: 2.8rem;

$wh-price-expand-icon: 2.8rem;

$h-price-content: 3.7rem;

$w-price-min-max-content: 17.2rem;
$h-price-min-max-content: 4.4rem;

$bg-color-rc-slider-track: #ffba1a;
$bg-color-rc-slider-rail: #00d8ff;

$bg-color-price-content: #082c6b;
$border-price-content: 3px solid #ffba1a;
$color-price-title: #ffba1a;

.price-container {
  .title-container {
    @apply flex items-center justify-between cursor-pointer;
    width: $w-price;
    height: $h-price;

    span {
      @apply font-black text-3xl uppercase tracking-wide;
      color: $color-price-title;
    }

    .icon {
      @apply bg-center bg-no-repeat bg-cover;
      width: $wh-price-expand-icon;
      height: $wh-price-expand-icon;
      &-minus {
        @apply bg-img-icon-minus bg-center bg-no-repeat bg-cover;
      }
      &-plus {
        @apply bg-img-icon-plus bg-center bg-no-repeat bg-cover;
      }
    }
  }

  .content-container {
    @apply hidden max-h-0 mt-4 rounded-xl justify-between items-center text-white font-bold text-3xl select-none;

    width: $w-price;
    height: $h-price-content;

    &.expand {
      @apply flex max-h-full;
    }

    .min-container {
      @apply flex rounded-xl items-center p-4;
      width: $w-price-min-max-content;
      height: $h-price-min-max-content;
      background-color: $bg-color-price-content;
      border: $border-price-content;
    }

    .max-container {
      @apply flex rounded-xl items-center p-4;
      width: $w-price-min-max-content;
      height: $h-price-min-max-content;
      background-color: $bg-color-price-content;
      border: $border-price-content;
    }
  }
}
