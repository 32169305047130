$w-coin-block: 25.7rem;
$h-coin-block: 19.7rem;

$bg-color-coin-block: #082c6b;
$border-coin-block: 2px solid #ffba1a;

$w-token-icon: 6.8rem;
$h-token-icon: 6.6rem;

$color-token-name: #00d8ff;
$fs-token-name: 1.6rem;
$fs-currency-1: 1.8rem;
$fs-currency-2: 1.6rem;

.coin-block-container {
  @apply p-8 rounded-xl flex flex-col justify-between;

  width: $w-coin-block;
  height: $h-coin-block;
  background-color: $bg-color-coin-block;
  border: $border-coin-block;

  .header-container {
    @apply w-full flex justify-between;

    .left-content {
      @apply flex flex-col;

      .token {
        @apply font-black;

        color: $color-token-name;
        font-size: $fs-token-name;
      }

      .currency-1 {
        @apply text-white font-bold;

        font-size: $fs-currency-1;
      }

      .currency-2 {
        @apply text-white font-normal;

        font-size: $fs-currency-2;
      }
    }

    .right-content {
      @apply h-auto flex justify-center items-center;

      .token-icon {
        @apply bg-img-idle-glory-coin bg-no-repeat bg-center bg-cover;

        width: $w-token-icon;
        height: $h-token-icon;
      }
    }
  }

  .footer-content {
    @apply sticky;
  }
}
