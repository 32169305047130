$w-img-logo: 32.4rem;
$h-img-logo: 15.9rem;

$h-footer: 6.7rem;

$bg-color-footer: radial-gradient(50% 14693.88% at 50% 50%, rgba(0, 0, 0, 0.5) 0%, rgba(0, 0, 0, 0.5) 0%, rgba(0, 0, 0, 0) 100%);

.footer {
  @apply mt-40;
  flex-shrink: 0;

  @apply w-full max-w-full flex justify-center items-center flex-wrap gap-12;
  height: $h-footer;
  background: $bg-color-footer;

  img {
    @apply mx-4;
    transform: translate(0%, -36%);
    width: $w-img-logo;
    height: $h-img-logo;
  }

  a {
    @apply text-white text-2xl -mt-20;
    height: $h-footer;
  }
}
