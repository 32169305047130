/* Fonts */
@import url('https://fonts.googleapis.com/css2?family=Exo:wght@400;500;700;900&display=swap');

@import 'react-toastify/dist/ReactToastify.css';
@import 'rc-slider/assets/index.css';

/* Tailwind CSS */
@import 'tailwindcss/base';
@import 'tailwindcss/components';
@import 'tailwindcss/utilities';

/* Custom CSS */
@import './components/buttons/buttons.scss';
@import './components/inputs/inputs.scss';
@import './components/layouts/layouts.scss';
@import './components/panels/panels.scss';
@import './components/displays/displays.scss';
@import './components/filters/filters.scss';
@import './components/form/form.scss';
@import './components/warning/warning.scss';

/* Pages CSS */
@import './pages/components/page-components.scss';
@import './pages/buy/buy.scss';
@import './pages/details/item-details.scss';
@import './pages/inventory/inventory.scss';
@import './pages/marketplace/marketplace.scss';

$bg-color-main: #242735;

@layer base {
  /**
    * Reset font-size for apply rem tricks, 1rem = 10px 
    * The purpose is that when user change the font-size settings of browser,
    * the elements will be scaled appropriately.
    */
  html {
    @apply cursor-default h-full;
    font-size: 62.5%;
  }

  body {
    @apply overflow-x-hidden;
    height: inherit;

    &.modal-opened {
      @apply overflow-y-hidden;
    }
  }

  #root {
    height: inherit;
  }

  a,
  button,
  img,
  input {
    @apply cursor-pointer;
  }

  .disabled,
  :disabled {
    @apply cursor-not-allowed;

    * {
      @apply cursor-not-allowed;
    }
  }

  .content {
    @apply px-24 pt-72 mx-auto w-full max-w-full;
    flex: 1 0 auto;
  }

  .bg-main {
    @apply w-full bg-img-container bg-no-repeat bg-bottom max-w-full mx-auto pb-16;
    height: auto;
    min-height: 100%;
    background-color: $bg-color-main;
    background-size: 100% auto;
    display: flex;
    flex-direction: column;
  }

  .loader {
    border-top-color: $bg-color-main;
    -webkit-animation: spinner 1.5s linear infinite;
    animation: spinner 1.5s linear infinite;
  }

  @-webkit-keyframes spinner {
    0% {
      -webkit-transform: rotate(0deg);
    }
    100% {
      -webkit-transform: rotate(360deg);
    }
  }

  @keyframes spinner {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
}
