$color: #705512;
$bg-color: #ffba1a;
$min-w-cell: 20rem;

.table-header-cell {
  @apply p-6 font-black lg:table-cell w-full;
  color: $color;
  background-color: $bg-color;
  min-width: $min-w-cell;
}
