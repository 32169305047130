$primary-color: #082c6b;
$secondary-color: #ffba1a;
$success-color: #28a55f;
$error-color: #dc2530;
$info-color: #45c1fe;

$primary-accent-color: #00d8ff;

.progress-value {
  @apply font-bold text-content ml-6;
}

.progress-wrapper {
  @apply w-full rounded-xl border-2 border-solid p-3.5 relative;
  background: $primary-color;
  border-color: $secondary-color;

  .progress-bar {
    @apply h-6 relative;

    .progress-line {
      @apply border-t border-solid absolute top-1/2 left-1/2 z-0;
      width: 99%;
      transform: translate(-50%, -50%);
      border-color: $primary-accent-color;
    }

    .progress {
      @apply h-full rounded-sm relative z-10 border;

      &-success {
        background-color: $success-color;
        border-color: $success-color;
      }

      &-error {
        background-color: $error-color;
        border-color: $error-color;
      }

      &-info {
        background-color: $info-color;
        border-color: $info-color;
      }
    }
  }

  .progress-title {
    @apply absolute uppercase text-white font-bold top-1/2 left-1/2 z-20;
    transform: translate(-50%, -50%);
  }
}
