$bg-color-item-default: #022258;

$color-accent-item-default: #7dfff4;

$border-color-active-item-default: #ffba1a;

.menu {
  @apply flex gap-6;

  &.vertical {
    @apply flex-col;
  }

  &.horizontal {
    @apply flex-row;
  }

  &-item {
    &-default {
      @apply text-content text-white py-7 px-24 rounded-xl;
      background-color: $bg-color-item-default;

      &.accent {
        @apply font-black;
        color: $color-accent-item-default;
      }

      &:hover,
      &.active {
        box-shadow: 0 0 0 3px $border-color-active-item-default;
      }

      &.disabled {
        @apply filter grayscale text-gray-300;

        &:hover {
          box-shadow: none;
        }
      }
    }
  }
}
