@use 'sass:meta';

@include meta.load-css('./components/container/table-container');

.table {
  @apply border-collapse w-full text-content;

  @include meta.load-css('./components/head/table-head');
  @include meta.load-css('./components/row/table-row');
  @include meta.load-css('./components/header-cell/table-header-cell');
  @include meta.load-css('./components/label/table-sort-label');
  @include meta.load-css('./components/body/table-body');
  @include meta.load-css('./components/cell/table-cell');
}
