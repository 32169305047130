$border-img-color: #ffba1a;
$border-list-color: #00d8ff;
$bg-color-hover: #705512;

.list {
  @apply w-full text-content flex flex-col text-white rounded-xl px-10 py-14 border-solid border;
  border-color: $border-list-color;

  &-container {
    @apply relative block w-full mx-auto;

    h6 {
      @apply text-white font-bold uppercase text-title mb-6 text-center;
    }
  }

  &-item {
    @apply flex px-6 py-9 rounded-lg gap-8;

    &:hover {
      background-color: $bg-color-hover;
    }

    &-avatar {
      @apply m-auto;

      img {
        @apply border border-solid rounded-md;
        border-color: $border-img-color;
      }
    }

    &-text {
      @apply flex flex-col justify-center;

      .primary {
        @apply text-info-header font-medium;
      }

      &.currency {
        @apply items-end;

        * {
          @apply items-end;
        }

        .primary {
          @apply font-bold;
        }
      }
    }

    .caption {
      @apply text-caption opacity-50 text-right;
    }
  }
}
