$color-hover: #55400a;

.table-sort-label {
  @apply inline-flex items-center cursor-pointer;

  svg {
    @apply ml-4;
  }

  &:hover {
    color: $color-hover;

    svg {
      fill: $color-hover;
    }
  }
}
