$color-primary: #082c6b;
$color-secondary: #ffba1a;
$color-success: #28a55f;
$color-error: #dc2530;

.alert {
  @apply flex text-content items-center;

  &-content {
    @apply ml-3;
  }

  &.info > p {
    color: $color-primary;
  }

  &.warning > p {
    color: $color-secondary;
  }

  &.success > p {
    color: $color-success;
  }

  &.error > p {
    color: $color-error;
  }
}
