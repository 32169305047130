$w-filter-accordion: 35rem;
$h-filter-accordion-title-container: 5.2rem;
$bg-color-filter-accordion-title-container: #082c6b;
$border-color-filter-accordion-title-container: 2px solid #ffba1a;
$color-filter-accordion-title: #ffba1a;
$wh-filter-accordion-icon: 2.8rem;

.filter {
  &-accordion {
    width: $w-filter-accordion;

    .title-container {
      @apply w-full rounded-2xl px-8 flex items-center justify-between cursor-pointer;

      background-color: $bg-color-filter-accordion-title-container;
      height: $h-filter-accordion-title-container;
      border: $border-color-filter-accordion-title-container;

      p {
        @apply font-black text-3xl uppercase;
        color: $color-filter-accordion-title;
      }

      .icon {
        width: $wh-filter-accordion-icon;
        height: $wh-filter-accordion-icon;
        &-minus {
          @apply bg-img-icon-minus bg-center bg-no-repeat bg-cover;
        }
        &-plus {
          @apply bg-img-icon-plus bg-center bg-no-repeat bg-cover;
        }
      }
    }

    .content-container {
      @apply overflow-hidden flex flex-col max-h-0 px-8;

      &.expand {
        @apply max-h-full;
      }

      .item-container {
        @apply flex items-center justify-start;

        input {
          display: none;
        }

        .icon {
          @apply p-0 my-6 mr-5 bg-btn-checkbox bg-center bg-cover bg-no-repeat bg-transparent;

          width: $wh-filter-accordion-icon;
          height: $wh-filter-accordion-icon;
        }

        input:checked + label > .icon {
          @apply bg-btn-checkbox-checked bg-center bg-cover bg-no-repeat bg-transparent;
        }

        label {
          @apply text-3xl font-bold text-white cursor-pointer select-none flex items-center;
        }
      }

      hr {
        border: 1px solid #5da8ec;
      }
    }
  }
}
