$bg-color-btn-logout: #022258;
$border-color-active-item-default: #ffba1a;

$w-bg-img: 35.2rem;
$w-btn-logout: 29.7rem;

.header-info {
  @apply h-auto relative;
  width: $w-bg-img;
  min-width: $w-bg-img;

  .header-info-container {
    @apply bg-img-header-info bg-center bg-no-repeat h-28 flex items-center justify-center px-11 cursor-pointer;
    background-size: $w-bg-img;

    .header-info-item {
      @apply flex-auto flex items-center justify-center gap-2 text-content text-white font-bold mb-3;
    }
  }

  .logout-container {
    @apply flex items-center justify-center -mt-2 px-11  absolute;

    .btn-logout {
      @apply rounded-none border-4 uppercase font-black;
      background-color: $bg-color-btn-logout;
      width: $w-btn-logout;

      &:before {
        background: none;
        box-shadow: none;
      }
    }
  }
}
