$w-input-outlined: 30.5rem;
$h-input-outlined: 5.1rem;
$color-input-outlined: #ffba1a;
$border-left-input-outline: 3px solid #ffba1a;
$border-right-input-outline: 3px solid #ffe9af;
$bg-image-input-outline: linear-gradient(90deg, #ffba1a, #ffe9af), linear-gradient(90deg, #ffba1a, #ffe9af);
$bg-size-input-outline: 100% 3px;
$bg-position-input-outline: 0 0, 0 100%;

.input {
  &-outlined {
    @apply py-5 px-7 outline-none placeholder-yellow-400 text-3xl rounded-2xl bg-no-repeat bg-transparent;
    width: $w-input-outlined;
    height: $h-input-outlined;
    color: $color-input-outlined;
    border-left: $border-left-input-outline;
    border-right: $border-right-input-outline;
    background-image: $bg-image-input-outline;
    background-size: $bg-size-input-outline;
    background-position: $bg-position-input-outline;
  }
}
